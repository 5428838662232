/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-case-declarations */

import api from '@/api'
import { arrayBufferToBase64, parseTime } from '@/utils'
import { ElMessage, ElNotification,ElMessageBox } from 'element-plus'
const QRCode = require('qrcodejs2')
import { defineComponent, ref, onMounted, reactive, watch, computed, toRefs, nextTick, Ref, ButtonHTMLAttributes } from 'vue'
import { useRouter } from 'vue-router'
import './index.scss'
import {loadStripe} from '@stripe/stripe-js'
import { useStore } from 'vuex'
import { UserLevel } from '@/store/modules/user'
import axios from 'axios'
import { getToken } from '@/utils/auth'


// 0->未支付；1->支付宝；2->微信 3->VISA 4->MASTER

const PayOrder = defineComponent({
  props: {
    changeType: {
      type: String,
      default: ''
    },
    clientWidth: {
      type: Number,
      default: 0
    },
    orderId: {
      type: Number,
      default: 0
    },
    onPaySuccess: Function
  },
  emits: ['paySuccess'],
  setup(props, { emit }){
    const router = useRouter()
    const store = useStore()

    const stripe = ref()
    const items = reactive({
      email: '',
      orderId: 0,
      orderSn: '',
      payType: '',
      price: '',
      title: '',
      orderType: ''
    })
    const elements: Ref<{[props: string]: any}> = ref({})

    let addressObj = reactive({
      receiverName: '',
      receiverPhone: '',
      receiverProvince: '',
      receiverCity: '',
      receiverRegion: '',
      receiverDetailAddress: ''
    })
    const detailObj = reactive({
      memberReceiveAddressId: '' as string | number,
      payType: '' as string | number,
      couponId: '' as string | number,
      cartIds: [] as number[]
    })
    const submitObj = reactive({
      orderId: '' as string | number,
      orderSn: '' as string | number,
      totalFee: '' as string | number
    })
    const couponList = ref([])
    const orderList = ref([])
    const toId = ref(0)
    const pushNum = ref(0)
    const show = ref(false)
    const payImg = ref('')
    const timer = ref({})
    const qrCodeUrl = ref({})
    const payId = ref('')
    const loading = ref(false)

    const { orderId, changeType, clientWidth } = toRefs(props)

    const tableList = [
      {label: '商品类型', width: '400px',  slot: {
        default: (scope: any): JSX.Element => <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            flexFlow: 'wrap'
          }}
          onClick={() => goDetail(scope.row.productId)}
        >
          {scope.row.productPic && <img
            src={scope.row.productPic}
            style={{
              width: '50px',
              marginRight: '10px'
            }}
            alt=""
          />}
          <span>{scope.row.productName}</span>
        </div>
      }},
      {label: '配送方式', slot: {
        default: (): JSX.Element => <span>普通配送快递 免邮</span>
      }},
      {label: '单价', slot: {
        default: (scope: any): JSX.Element => <span>{scope.row.productPrice}</span>
      }},
      {label: '数量', prop: 'productQuantity'},
      {label: '金额', slot: {
        default: (scope: any): JSX.Element => <span>{scope.row.productPrice * scope.row.productQuantity}</span>
      }},
    ]

    function goDetail(id: number): void {
      if(document.body.clientWidth < 900) {
        // TODO 跳转的mobile
        window.location.href = `http://mobile.taosseract.com/pages/mall/detail?id=${id}`
      } else {
        toId.value = id
        pushNum.value++
      }
    }

    watch(pushNum, (num: number) => {
      if(num) {
        router.push({
          name: 'SanchuangDetail',
          params: { id: toId.value }
        })
      }
    })


    const payList = [
      {title: '微信支付', value: 2},
      {title: '支付宝支付', value: 1},
      {title: '积分支付', value: 5},
      {title: 'VISA支付', value: 3},
      // {title: 'Master支付', value: 4},
      {title: 'Master支付', value: 4},


    ]

    /**
     * @description 根据地址生成二维码
     * @author Jinx
     * @date 2021-10-29 10:34:54
     * @param {string} url
     */
    function qrCode(url: string): void {
      const qrcode = new QRCode(qrCodeUrl.value, {
        width: 300, //图像宽度
        height: 300, //图像高度
        colorDark: "#000000", //前景色
        colorLight: "#ffffff", //背景色
        typeNumber: 4,
        correctLevel: QRCode.CorrectLevel.H
        //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
      })
      qrcode.clear()
	    qrcode.makeCode(url)
    }

    function getList(): void {
      Promise.all([
        api.orderDetail(orderId.value),
        api.couponList({}),
      ]).then(res => {
        // 地址
        const obj = addressObj as any
        for(const i in addressObj) {
          obj[i] = res[0].data[i]
        }
        addressObj = obj

        // 优惠券
        couponList.value = res[1].data && res[1].data[0] ? res[1].data : []
        detailObj.couponId = res[0].data.couponId || ''

        // 订单
        orderList.value = res[0].data?.orderItemList || []
        submitObj.orderSn = res[0].data?.orderSn
        submitObj.orderId = res[0].data?.id

        // 支付方式
        detailObj.payType = res[0].data?.payType

        if(changeType.value === 'now') {
          pay()
        }
      })
    }

    function choosePay(value: number): void {
      console.log(value)

      detailObj.payType = value
    }
    function chooseCoupon(item: any): void {
      console.log(item)
    }

    async function pay(): Promise<void> {
      const GetUserLevel = store.getters.GetUserLevel as UserLevel
      const obj = Object.assign({}, submitObj)
      const { data } = await api.getStripeKey()

      obj.orderId = orderId.value
      obj.totalFee = endPrice.value
      if(GetUserLevel.discount || +obj.totalFee !== 0) {
        switch (detailObj.payType) {
          case 1:
            // 支付宝支付
            if(clientWidth.value <= 900) {
              axios({
                url: '/mall-portal/payment/alipay',
                method: 'post',
                headers: {
                  Authorization: getToken()
                },
                params:obj
              }).then(res => {
                const oDiv = document.createElement('divform')
                oDiv.innerHTML = res.data
                document.body.appendChild(oDiv)
                document.forms[0].acceptCharset='GBK';
                document.forms[0].submit();
              })
            } else {
              api.paymentPay({
                price: obj.totalFee,
                goodsId: obj.orderId,
                goodsName: orderList.value.reduce((preV, curV: any) => preV + curV.productName, ''),
                subject: orderList.value.reduce((preV, curV: any) => preV + curV.productName, ''),
                body: 'order',
                quantity: 1
              }).then(res => {
                show.value = true
                nextTick(() => qrCode(res.data))
                timer.value = setInterval(() => {
                  api.wxPayPayStatus({outTradeNo: `order-${obj.orderId}`}).then(res => {
                    if(res.data === 1) {
                      ElMessage({type: 'error', message: '支付失败'})
                      clearInterval(timer.value as NodeJS.Timeout)
                      timer.value = {}
                      dialogClose()
                    } else if(!res.data) {
                      show.value = false
                      clearInterval(timer.value as NodeJS.Timeout)
                      timer.value = {}
                      emit('paySuccess', obj.totalFee)
                    }
                  })
                },5000)
              })
            }
            break
          case 2:
            // 微信支付
            if(clientWidth.value <= 900) {
              api.wx_pay_other({
                id: obj.orderId,
                totalFee: obj.totalFee ,
                type: '',
                url: 'https://www.taosseract.com/mine/order'
              }).then(res => {
                ElMessageBox.confirm('支付状态', '是否支付成功？', {
                  confirmButtonText: '支付成功',
                  cancelButtonText: '退出',
                  type: 'warning'
                }).then(() => {
                  setTimeout(() => {
                    router.push({
                      name: 'MineOrder'
                    })
                  }, 3000)
                })
                window.location.href = res.data
                // window.location.href = 'https://www.taosseract.com/mine/order'

              })
            } else {
              api.wxPayPayUrl(obj).then(({ data }) => {
                const img = arrayBufferToBase64(data)
                payImg.value = img
                show.value = true
                clearInterval(timer.value as NodeJS.Timeout)
                timer.value = setInterval(() => {
                  api.wxPayPayStatus({outTradeNo: `order-${obj.orderSn}`}).then(res => {

                  // api.wxPayPayStatus({outTradeNo: obj.orderSn as string}).then(res => {
                    if(res.data === 1) {
                      ElMessage({type: 'error', message: '支付失败'})
                      clearInterval(timer.value as NodeJS.Timeout)
                      timer.value = {}
                      dialogClose()
                    } else if(!res.data) {
                      show.value = false
                      clearInterval(timer.value as NodeJS.Timeout)
                      timer.value = {}
                      emit('paySuccess', obj.totalFee)
                    }
                  })
                },5000)
              })
            }
            break
          case 3:
          case 4:
          case 5:
              stripe.value = await loadStripe(data)
              items.email = '526771102@qq.com'
              items.orderId = obj.orderId
              items.orderSn = obj.orderSn as string
              items.payType = '测试',
              items.price = obj.totalFee as string,
              items.title = '测试'
              items.orderType = 'order'
              initialize()
            break
          default:
        }
      } else {
        api.orderPaySuccess({
          no: obj.orderSn as string,
          orderId: String(obj.orderId),
          payType: detailObj.payType as string,
          payId: String(obj.orderId)
        }).then(() => {
          ElNotification({
            type: 'success',
            title: '成功',
            message: '支付成功'
          })
          setTimeout(() => {
            router.push({
              name: 'MineOrder'
            })
          }, 1000)
        })
      }
    }

    // 获取付款意图并捕获客户端机密
    async function initialize() {
      try {
        loading.value = true
        const { data } = await api.paymentRender(items)
        loading.value = false
        show.value = true
        nextTick(() => {
          const {clientSecret} = JSON.parse(data)
          payId.value = clientSecret
          const appearance = {
            theme: 'stripe',
          }
          elements.value = stripe.value.elements({ appearance, clientSecret }) as any

          const paymentElement = elements.value.create("payment")
          paymentElement.mount("#payment-element")
        })
      } catch (error) {
        loading.value = false
        if(error) {
          ElNotification({
            type: 'error',
            message: '订单生成失败，请稍后再试'
          })
          return
        }
      }
    }

    async function handleSubmit(e: Event): Promise<any> {
      e.preventDefault()
      setLoading(true)
      const return_url = window.location.href
        + '&price=' + endPrice.value
        + '&payId=' + payId.value
        + '&payType=' + detailObj.payType
        + '&orderId=' + submitObj.orderId
        + '&orderSn=' + submitObj.orderSn
      const { error } = await stripe.value.confirmPayment({
        elements: elements.value,
        confirmParams: {
          return_url
        },
      })
      if (error.type === "card_error" || error.type === "validation_error") {
        showMessage(error.message)
      } else {
        showMessage('一个意料之外的问题发生了')
      }
      setLoading(false)
    }

    // ------- 用户界面助手 -------

    function showMessage(messageText: string | any): void {
      const messageContainer = document.querySelector("#payment-message") as Element

      messageContainer.classList.remove("hidden")
      messageContainer.textContent = messageText

      setTimeout(function () {
        messageContainer.classList.add("hidden")
        messageText.textContent = ""
      }, 4000)
    }

    // 在付款提交时显示微调器
    function setLoading(isLoading: boolean) {
      if (isLoading) {
        // 禁用按钮并显示微调器
        const submit = document.querySelector("#submit") as ButtonHTMLAttributes
        submit.disabled = true
        document.querySelector("#spinner")?.classList.remove("hidden")
        document.querySelector("#button-text")?.classList.add("hidden")
      } else {
        const submit = document.querySelector("#submit") as ButtonHTMLAttributes
        submit.disabled = false
        document.querySelector("#spinner")?.classList.add("hidden")
        document.querySelector("#button-text")?.classList.remove("hidden")
      }
    }


    function dialogClose(): void {
      if(changeType.value === 'now') {
        router.push({
          name: 'MineOrder'
        })
      }
      clearInterval(timer.value as NodeJS.Timeout)
      timer.value = {}
      show.value = false
      payImg.value = ''
    }

    const endPrice = computed(() => {
      return orderList.value.reduce((preV: any, curV: any) => preV + curV.productPrice * curV.productQuantity   , 0)
    })


    function dialogDom(): JSX.Element {
      return <el-dialog
        title=""
        v-model={ show.value }
        width="400px"
        before-close={ dialogClose }
        close-on-click-modal={false}
        custom-class="pay_order-dialog"
      >
        {[3, 4, 5].includes(detailObj.payType as number) && <form id="payment-form">
          <div id="payment-element">
          </div>
          <div id="submit" onClick={handleSubmit}>
            <div class="spinner hidden" id="spinner"></div>
            <span id="button-text">立即支付</span>
          </div>
          <div id="payment-message" class="hidden"></div>
        </form>}
        <div ref={qrCodeUrl}></div>
        <img src={payImg.value} alt="" />
        {detailObj.payType === 1 && <span style={{marginTop: '10px', display: 'block'}}>请使用支付宝支付（请在5分钟内付款）</span>}
        {detailObj.payType === 2 && <span style={{marginTop: '10px', display: 'block'}}>请使用微信支付（请在5分钟内付款）</span>}
      </el-dialog>
    }


    onMounted(() => {
      getList()
    })

    return () => <div class="pay_order">
      {show.value && dialogDom()}
      <div class="pay_order-title">收获地址</div>
      <div class="pay_order-address">
        <div
          class={[
            'address-list',
            'address-list-active'
          ]}
        >
          <div class="address-list-header">
            <div class="address-list-header-name">{addressObj.receiverName}</div>
            <div class="address-list-header-phone">{addressObj.receiverPhone}</div>
          </div>
          <p>{addressObj.receiverProvince} {addressObj.receiverCity} {addressObj.receiverRegion} {addressObj.receiverDetailAddress}</p>
        </div>
      </div>
      <div class="pay_order-title">支付方式</div>
      <div class="pay_order-pay">
        {payList.map((item, index) => <div
          class={[
            'pay-list',
            detailObj.payType === item.value ? 'pay-list-active' : 'pay-list-primary'
          ]}
          onClick={() => choosePay(item.value)}
        >
          <div class={[`pay-list-img_${index}`]} />
          <div>{item.title}</div>
        </div>)}
      </div>
      <div class="pay_order-title">优惠券</div>
      <div class="pay_order-coupon">
        {couponList.value.map((item: any) => detailObj.couponId === item.id && <div
          class={[
            'coupon-list',
            detailObj.couponId === item.id ? 'coupon-list-active' : 'coupon-list-primary'
          ]}
          onClick={() => chooseCoupon(item)}
        >
          <div>HK${item.amount}</div>
          <div>有效期至{parseTime(new Date(item.endTime).getTime(), '{y}-{m}-{d}')}</div>
          <div>【{item.name}】</div>
        </div>)}
      </div>
      <div class="pay_order-title">商品详情</div>
      {
        clientWidth.value <= 900
          ? <div class="m-shop-content">
              {orderList.value.map((item: any) => <div
                style={{
                  width: '100%'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    padding: '10px'
                  }}
                >
                  <img src={item.productPic} alt="" style={{ width: '74px', marginLeft: '10px' }} />
                  <div
                    style={{
                      flex: '1',
                      paddingLeft: '15px'
                    }}
                  >
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      <div style={{
                        fontSize: '14px',
                        color: '#302928'
                      }}>{item.productName}</div>
                      <span style={{
                        fontSize: '13px',
                        color: '#302928'
                      }}>单价：{item.price}</span>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
                      <span style="color: #878787">数量：</span>
                      <div>{item.quantity}</div>
                    </div>
                  </div>
                </div>
              </div>)}
            </div>
          : <el-table
            data={orderList.value}
            class="confirm_order-list"
            width="100%"
          >
            {tableList.map(item => <el-table-column
              prop={item.prop}
              label={item.label}
              align="center"
              v-slots={item.slot}
            />)}
          </el-table>
      }
      <div class="pay_order-bottom">
        <div>实付款：</div>
        <div>HK$</div>
        <div>{endPrice.value}</div>
      </div>
      <div class="pay_order-btn">
        {
          detailObj.payType
            ? <div class="pointer" onClick={pay}>
              {loading.value && <i class="el-icon-loading" style="margin-right: 10px" />}
              <span>确认付款</span>
            </div>
            : <div class="noDrop" title="请选择支付方式">确认付款</div>
        }
      </div>
    </div>
  }
})

export default PayOrder

import BreadCrumbs from "@/components/BreadCrumbs";
import { Vue } from "vue-class-component";
import ConfirmOrder from "./components/ConfirmOrder";
import PayOrder from "./components/PayOrder";
import './index.scss'
import { h } from 'vue'
import { ElMessage, ElMessageBox } from "element-plus";
import api from "@/api";

export default class SanchuangPay extends Vue {
  public activeNum = 1

  public orderId = 0

  public changeType = ''
  public orderPrice = ''
  public minTime = 5
  public timer: any = {}
  public clientWidth = 0

  payBack(row: any): void {
    ElMessageBox.confirm('确认付款？', '付款', {
      type: 'warning'
    }).then(() => {
      this.$store.dispatch('backNumPlus')
      this.orderId = row.order.id
      this.activeNum = 2
      this.changeType = 'now'
    }).catch(() => {
      // 没有付款去 订单页面
      this.$router.push({
        name: 'MineOrder'
      })
    })
  }

  /**
   * @description 支付成功
   * @author Jinx
   * @date 2021-10-28 16:55:43
   * @memberof SanchuangPay
   */
  paySuccess(price: string): void {
    this.$nextTick(() => {
      this.orderPrice = price
      this.activeNum = 3
      clearInterval(this.timer)
      setInterval(() => {
        if(!this.minTime) {
          clearInterval(this.timer)
          this.timer = {}
        }
        this.minTime --
      }, 1000)
      const timeout = setTimeout(() => {
        this.$router.push({
          name: 'MineOrder'
        })
        clearTimeout(timeout)
      }, 5000)
    })
  }

  render(): JSX.Element {
    return <div class="sanchuang_pay">
      <BreadCrumbs />
      <div class="sanchuang_pay-header">
        <el-steps active={this.activeNum} finish-status="success">
          <el-step title="选择商品" />
          <el-step title="确认订单" />
          <el-step title="支付订单" />
          <el-step title="等待收货" />
        </el-steps>
      </div>
      {
        (() => {
          switch (this.activeNum) {
            case 1:
              return h(ConfirmOrder, {
                clientWidth: this.clientWidth,
                onPayBack: this.payBack
              })
            case 2:
              return <PayOrder
                clientWidth={this.clientWidth}
                orderId={ this.orderId }
                changeType={ this.changeType }
                onPaySuccess={ this.paySuccess }
              />
            case 3:
              return <div
                class="sanchuang_pay-success"
              >
                <div class="sanchuang_pay-success-title">
                  <i class="el-icon-check" />
                  <span>支付成功</span>
                </div>
                <div class="sanchuang_pay-success-message">您已成功付款HK$ <span>{this.orderPrice}</span></div>
                <div
                  class="sanchuang_pay-success-order"
                  onClick={() => this.$router.push({
                    name: 'MineOrder'
                  })}
                >
                  查看我的订单 {this.minTime}s后跳转
                </div>
              </div>
            default:
              return <div/>
          }
        })()
      }
    </div>
  }

  created(): void {
    if(this.$route.query?.redirect_status) {
      switch (this.$route.query.redirect_status) {
        case "succeeded":
          api.orderPaySuccess({
            no: this.$route.query.orderSn as string,
            orderId: this.$route.query.orderId as string,
            payType: this.$route.query.payType as string,
            payId: this.$route.query.payId as string
          })
          this.paySuccess(this.$route.query.price as string)
          return
        case "processing":
          ElMessage({
            type: 'error',
            message: '您的付款正在处理中'
          })
          return
        case "requires_payment_method":
          ElMessage({
            type: 'error',
            message: '您的付款未成功，请重试'
          })
          return
        default:
          ElMessage({
            type: 'error',
            message: '出了些问题'
          })
          return
      }
    }
    if (this.$route.query?.order_id) {
      this.orderId = +this.$route.query?.order_id
      this.activeNum = 2
    }
  }
  mounted(): void {
    this.clientWidth = document.body.clientWidth
    window.onresize = () => {
      this.clientWidth = document.body.clientWidth
    }
  }
}

import api from '@/api'
import { UserLevel } from '@/store/modules/user'
import { parseTime } from '@/utils'
import { UmsAddress } from '@/views/address'
import { ElNotification } from 'element-plus'
import { defineComponent, ref, toRefs, onMounted, reactive, watch, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import './index.scss'

// 0->未支付；1->支付宝；2->微信 3->VISA 4->MASTER

const ConfirmOrder = defineComponent({
  props: {
    clientWidth: {
      type: Number,
      default: 0
    }
  },
  setup(props, { emit }){
    const { clientWidth } = toRefs(props)
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const addressList = ref([])
    const submitObj = reactive({
      memberReceiveAddressId: '' as string | number,
      payType: '' as string | number,
      couponId: '' as string | number,
      couponAmount: 0 as number,
      cartIds: [] as number[]
    })
    const couponList = ref([])
    const orderList = ref([])
    const toId = ref(0)
    const pushNum = ref(0)

    const tableList = [
      {label: '商品类型', width: '400px',  slot: {
        default: (scope: any): JSX.Element => <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
          }}
          onClick={() => goDetail(scope.row.productId)}
        >
          {scope.row.productPic && <img
            src={scope.row.productPic}
            style={{
              width: '50px',
              marginRight: '10px'
            }}
            alt=""
          />}
          <span>{scope.row.productName}</span>
        </div>
      }},
      {label: '配送方式', slot: {
        default: (): JSX.Element => <span>普通配送快递 免邮</span>
      }},
      {label: '单价', slot: {
        default: (scope: any): JSX.Element => <span>{scope.row.price}</span>
      }},
      {label: '数量', prop: 'quantity'},
      {label: '金额', slot: {
        default: (scope: any): JSX.Element => <span>{scope.row.price * scope.row.quantity}</span>
      }},
    ]

    function goDetail(id: number): void {
      if(document.body.clientWidth < 900) {
        // TODO 跳转的mobile
        window.location.href = `http://mobile.taosseract.com/pages/mall/detail?id=${id}`
      } else {
        toId.value = id
        pushNum.value++
      }
    }

    watch(pushNum, (num: number) => {
      if(num) {
        router.push({
          name: 'SanchuangDetail',
          params: { id: toId.value }
        })
      }
    })

    const payList = [
      {title: '微信支付', value: 2},
      {title: '支付宝支付', value: 1},
      {title: 'PayMe支付', value: 5},
      {title: 'VISA支付', value: 3},
      {title: 'Master支付', value: 4},
    ]

    function getList(): void {
      Promise.all([
        api.memberAddressList(),
        api.couponList({}),
        api.cartList()
      ]).then(res => {
        // 地址
        addressList.value = res[0].data
        submitObj.memberReceiveAddressId = res[0].data.find((item: UmsAddress) => item.defaultStatus === 1)?.id

        // 优惠券
        couponList.value = res[1].data && res[1].data[0] ? res[1].data : []
        // 订单
        const ids = route.query.ids as string
        submitObj.cartIds = ids.split(',').map(item => +item)
        orderList.value = res[2].data.filter((item: any) => ids.split(',').includes(String(item.id)))
      })
    }

    function chooseAddress(id: number): void {
      submitObj.memberReceiveAddressId = id
    }
    function choosePay(value: number): void {
      submitObj.payType = value
    }
    function chooseCoupon(item: any): void {
      submitObj.couponAmount = 0
      if(item){
        submitObj.couponAmount = item.amount
      }
      if(item.id === submitObj.couponId) {
        submitObj.couponId = ''
      } else {
        submitObj.couponId = item.id
      }
    }

    function addressEdit(id: number): void {
      router.push({
        name: 'Address',
        query: {
          back: 'SanchuangPay',
          ids: route.query.ids,
          id
        }
      })
    }

    function pay(): void {
      const GetUserLevel = store.getters.GetUserLevel as UserLevel
      const obj = Object.assign({}, submitObj)
      api.orderGenerateOrder(obj).then(res => {
        if(GetUserLevel.discount || +endPrice.value !== 0) {
          emit('payBack', res.data)
        } else {
          const { order } = res.data
          api.orderPaySuccess({
            no: order.orderSn,
            orderId: order.id,
            payType: obj.payType as string,
            payId: order.id
          }).then(() => {
            ElNotification({
              type: 'success',
              title: '成功',
              message: '购买成功'
            })
            setTimeout(() => {
              router.push({
                name: 'MineOrder'
              })
            }, 1000)
          })
        }
      })
    }

    const endPrice =  computed(() => {
      const price = orderList.value.reduce((preV: any, curV: any) => preV + curV.price * curV.quantity, 0) // 总金额
      // const coupon = couponList.value.find(item => item.id === submitObj.couponId)
      // const amount = coupon ? coupon.amount : 0 // 优惠金额
      const end = price - submitObj.couponAmount;
      if( end > 0 ){
        return end.toFixed(2)
      }else{
        return 0;
      }
    })

    onMounted(() => {
      getList()
    })

    return () => <div class="confirm_order">
      <div class="confirm_order-title">收获地址</div>
      <div class="confirm_order-address">
        {addressList.value.map((item: UmsAddress) => <div
          class={[
            'address-list',
            submitObj.memberReceiveAddressId === item.id ? 'address-list-active' : 'address-list-primary'
          ]}
          onClick={() => chooseAddress(item.id as number)}
        >
          <div class="address-list-header">
            <div class="address-list-header-name">{item.name}</div>
            <div class="address-list-header-phone">{item.phoneNumber}</div>
            <div class="address-list-header-edit" onClick={() => addressEdit(item.id as number)}>修改</div>
          </div>
          <p>{item.province} {item.city} {item.region} {item.detailAddress}</p>
        </div>)}
      </div>
      <div class="confirm_order-title">支付方式</div>
      <div class="confirm_order-pay">
        {payList.map((item, index) => <div
          class={[
            'pay-list',
            submitObj.payType === item.value ? 'pay-list-active' : 'pay-list-primary'
          ]}
          onClick={() => choosePay(item.value)}
        >
          <div class={[`pay-list-img_${index}`]} />
          <div>{item.title}</div>
        </div>)}
      </div>
      <div class="confirm_order-title">优惠券</div>
      <div class="confirm_order-coupon">
        {couponList.value?.map((item: any) => <div
          class={[
            'coupon-list',
            submitObj.couponId === item.id ? 'coupon-list-active' : 'coupon-list-primary'
          ]}
          onClick={() => chooseCoupon(item)}
        >
          <div>HK${item.amount}</div>
          <div>有效期至{parseTime(new Date(item.endTime).getTime(), '{y}-{m}-{d}')}</div>
          <div>【{item.name}】</div>
        </div>)}
      </div>
      <div class="confirm_order-title">商品详情</div>
      {
        clientWidth.value <= 900
          ? <div class="m-shop-content">
              {orderList.value.map((item: any) => <div
                style={{
                  width: '100%'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    padding: '10px'
                  }}
                >
                  <img src={item.productPic} alt="" style={{ width: '74px', marginLeft: '10px' }} />
                  <div
                    style={{
                      flex: '1',
                      paddingLeft: '15px'
                    }}
                  >
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      <div style={{
                        fontSize: '14px',
                        color: '#302928'
                      }}>{item.productName}</div>
                      <span style={{
                        fontSize: '13px',
                        color: '#302928'
                      }}>单价：{item.price}</span>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
                      <span style="color: #878787">数量：</span>
                      <div>{item.quantity}</div>
                    </div>
                  </div>
                </div>
              </div>)}
            </div>
          : <el-table
            data={orderList.value}
            class="confirm_order-list"
            width="100%"
          >
            {tableList.map(item => <el-table-column
              prop={item.prop}
              label={item.label}
              align="center"
              v-slots={item.slot}
            />)}
          </el-table>
      }
      <div class="confirm_order-bottom">
        <div>实付款：</div>
        <div>HK$</div>
        <div>{endPrice.value}</div>
      </div>
      <div class="confirm_order-btn">
        {
          submitObj.payType && submitObj.memberReceiveAddressId
            ? <div class="pointer" onClick={pay}>确认订单</div>
            : <div class="noDrop" title="请选择支付方式和收货地址">确认订单</div>
        }
      </div>
    </div>
  }
})

export default ConfirmOrder
